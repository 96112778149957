<template>
    <div class="wrap">
        <BaseNav v-if="isHasNav"></BaseNav>

        <AmisRender
            :jsonData="amisJSON"
            :jsonOtherData="jsonOtherData"
            v-if="loading"
            type="send"
        ></AmisRender>

        <el-backtop
            :bottom="80"
            :right="60"
            style="box-shadow: 0 0 6px #76b0ff"
        >
            <el-avatar
                :size="40"
                :src="require('./top.png')"
            ></el-avatar>
        </el-backtop>
    </div>
</template>

<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-console -->
<script>
    import { getSendFlowInfo, getMyReqInfo } from "@/api/flow";
    import AmisRender from "@/components/AmisRender";
    import BaseNav from "@/components/BaseNav";

    import { formatTime } from "@/utils/DateUtil";
    import { testJsonData } from "./const.js";

    export default {
        data() {
            return {
                id: "",
                saveId: "",
                amisJSON: {},
                jsonOtherData: {},

                infoData: {},

                loading: false,
            };
        },
        components: {
            AmisRender,
            BaseNav,
        },
        watch: {},
        async created() {
            this.id = this.$route.query.id;
            this.saveId = this.$route.query.saveId;
            console.log("this.$route.query -> :", this.$route.query);

            if (this.saveId) {
                await this.getInfo();
            }

            await this.getData();
        },
        methods: {
            async getInfo() {
                await getMyReqInfo({ saveId: this.saveId })
                    .then((res) => {
                        if (res.status == 200) {
                            const infoData = JSON.parse(res.data.formVariables || "{}");
                            console.log("infoData -> :", infoData);

                            this.infoData = infoData;
                        }
                    })
                    .catch(() => {
                        this.infoData = {};
                    });
            },
            getData() {
                let params = {
                    id: this.id,
                    suspensionState: 1,
                };
                getSendFlowInfo(params).then((res) => {
                    if (res.status == 200) {
                        if (res.data) {
                           
                            const formConf = res.data[0]?.formConf;
                            const name = res.data[0]?.name;

                            const jsonData = JSON.parse(formConf || "{}");
                            // jsonData.body[0].debug = true;
                            console.log('-------------------jsonData',jsonData);
                            // 去除上传的文件预览
                            jsonData.body[0]?.body.forEach((item) => {
                                if(item.disabled==true){
                                    item.required = false;
                                }
                                if (["input-file"].includes(item.type) && !this.isMyRequest) {
                                    item.downloadUrl = false;
                                }
                            });

                            this.amisJSON = testJsonData || jsonData;

                            this.jsonOtherData = {
                                processDefinitionId: this.id,
                                userId: localStorage.getItem("userId"),
                                deptId: localStorage.getItem("deptId"),
                                postId: localStorage.getItem("postId"),
                                bankAccount: localStorage.getItem("bankAccount"),
                                bankAccountNumber: localStorage.getItem("bankAccountNumber"),
                                instanceName: `${name}-${localStorage.getItem(
                                    "username"
                                )}-${formatTime("", "YYYY-MM-DD")}`,
                                ...this.infoData,
                            };
                            this.loading = true;
                        }
                    }
                });
            },
        },
        computed: {
            isHasNav() {
                return this.$route.query.hasNav;
            },
            isMyRequest() {
                return this.$route.query.page == "myRequest";
            },
        },
    };
</script>

<style lang="less" scoped>
    .wrap {
        padding: 0 10px;
    }
</style>
